body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navigation-area {
  display: flex;
  justify-content: center;
  background-color: #e6e6e6;
}

.main-area {
  padding-top: 50px;
  padding-left: 50px;
}

h2 {
  padding-top: 50px;
  padding-bottom: 20px;
}

.left-nav-bar {
  padding-left: 30px;
}

.left-nav-bar button {
  margin-top: 30px;
}
.left-nav-bar a {
  margin-top: 30px;
}

.nav-button {
  width: 85%;
}

.navbar-brand {
  text-transform: uppercase;
  padding-left: 10px;
}

.job-type-button {
  width: 250px;
}

.login-page {
  background-color: #212529;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: #ffffff;
  width: 50%;
  padding: 50px;
}

.runs-table-list {
  margin-top: 100px;
}

.highlighted-text {
  background-color: yellow;
}

.table-paginator-middle-button button {
  min-width: 175px;
}

.table-sorter button {
  min-width: 175px;
  text-align: left;
}

.table-sorter .dropdown-toggle::after {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color-selection {
  padding-left: 5px;
}

/*****    Header  ******/
.navbar-brand {
  font-style: italic;
  text-shadow: 1px 2px 2px #f8890c;
}

.LogoInitial {
  color: #f8890c;
  font-size: 1.5rem;
  font-style: normal;
  margin-left: 10px;
  text-shadow: 1px 2px 2px #c00;
}

/*****    Navigation  ******/
nav.navbar {
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.navbar .justify-content-end {
  margin-right: 10px;
}

.bg-dark {
  box-shadow: 2px 5px 5px #636363;
}

.navigation-area.col-md-2 {
  min-width: 232px;
  padding-left: 0px !important;
}

.navigation-area {
  background: #fff;
  border-right: solid 1px #000;
  padding-right: 0px !important;
}

.custom-nav-bar {
  border-bottom-right-radius: 14px;
  box-shadow: 2px 5px 5px #636363;
  height: auto;
  left: 0;
  margin-top: 62px;
  position: fixed;
  width: 232px;
  z-index: 100;
}

.custom-nav-bar a {
  background: #e6e6e6;
  border-bottom: solid 1px #f8890c;
  color: #343a40;
  display: block;
  font-weight: 600;
  padding: 5px 0px 5px 20px;
  padding-left: 20px;
  width: 100%;
}

.custom-nav-bar a:focus {
  outline: none;
}

.custom-nav-bar a:last-of-type {
  border-bottom-right-radius: 14px;
}

.custom-nav-bar a.active,
.custom-nav-bar a:hover {
  background-color: #343a40;
  border-right: solid 1px #f8890c;
  color: #f8890c;
  font-style: italic;
  text-decoration: none;
}

.custom-nav-bar a.active::before,
.custom-nav-bar a:hover::before {
  color: #c00;
  content: "\25BA";
  font-style: normal;
  margin-left: -14px;
}

.custom-nav-bar hr {
  border-top: soild 2px rgba(248, 137, 12, 1);
  margin: 0;
}

/*****    News Feed  ******/
.news-feed-datepicker {
  margin-right: 10px;
  margin-left: 10px;
}

.news-feed-truck-description {
  padding-left: 20px;
}

.news-feed-truck-code {
  padding-left: 50px;
  font-weight: bold;
}

.job-type-button {
  width: 250px;
  color: #fff;
  text-align: center;
}

.runs-header {
  border-bottom: 1px solid #f8890c;
  color: #c00
}

.sub-header {
  border-bottom: 1px solid #f8890c;
  margin-top: 5px;
  width: 250px;
  color: #c00
}

.form-row.rec-form-row {
  flex-wrap: nowrap;
}

.form-control.rec-text {
  width: 120px;
  height: 37px;
  margin: 0 10px;
}

.check-margin {
  margin-left: 10px;
}

body,
html {
  margin: 0px;
  padding: 0px;
}

.h-100 {
  padding: 0px;
}

/***** run page *******/
.run-datepicker {
  margin-top: 20px;
  margin-left: 20px;
}

.run-datepicker-label {
  margin-left: 3px;
}

.run-table {
  margin-top: 20px;
}

.run-form {
  padding-bottom: 50px;
}

.duration-row {
  margin-top: 15px;
}

.card-deck > .run-card.card {
  margin: 20px 15px;
  min-width: 18rem;
  max-width: 21.3rem;
}

.card-deck > .run-card.card > .card-body.card-completed,
.card-deck > .run-card.card > .list-group > .list-group-item.card-completed {
  background-color: #E9EDEA;
}

.check-completed,
.completed-checkmark {
  color: #51A451;
}

.completed-checkmark {
  font-size: 30px;
  margin-bottom: 2px;
}

.card-trash {
  margin-left: 20px;
}

.card-trash:hover {
  cursor: pointer;
}

.card-body > .data-select-group > label.data-select-label {
  margin-bottom: 0;
    margin-right: 5px;
    align-self: center;
}

.run-card.card .move-run-select {
  width: 200px;
  margin-bottom: 1rem;
}

.run-card.card .edit-icon {
  margin-right: 5px;
}

.loading {
  position: fixed;
  background-color: rgba(0,0,0, 0.3);
  top: 62px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.loading .spinner {
  position: absolute;
  top: 40%;
  left: 43%;
  font-size: 50px;
  width: 200px;
  height: 200px;
}



/*****  main page *****/
.col .container {
  margin-bottom: 30px;
  margin-top: 45px;
}

.col .container .container {
  margin: 20px 0;
}

h2,
h4 {
  border-bottom: solid 1px #f8890c;
  font-style: italic;
}

h2,
h4 {
  color: #c00;
  padding-bottom: 0px;
}

h2:after {
  color: #f8890c;
  content: "\25BA";
  float: left;
  font-style: normal;
  margin-left: -5px;
  margin-top: 18px;
}

.btn.btn-link {
  color: #f8890c;
}
.btn.btn-link:hover,
.btn.btn-link:active {
  color: #c00;
}

button.btn-primary {
  background-color: #f8890c;
  border: solid 1px #c00;
  font-weight: bolder;
}

button.btn-secondary {
  background-color: #0d7af8;
  border: solid 1px #03346c;
  font-weight: bolder;
}

button.btn-primary:hover,
button.btn-primary:active {
  background-color: #c00;
  border: solid 1px #f00;
}

.truck-description {
  margin-left: 10px;
}

.category {
  margin: 20px 0 10px;
}

.basic-single {
  width: 200px;
}
